/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import netlifyIdentity from 'netlify-identity-widget';

export const onClientEntry = () => {
	// IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
	if (typeof window.IntersectionObserver === `undefined`) {
		import(`intersection-observer`);
		console.log(`# IntersectionObserver is polyfilled!`);
	}
};

console.log(
	'%c Built by Dan Proudfoot %c http://danproudfoot.co.uk',
	'font-size: 20px; background: #163558; color: #bbc8e6',
	'font-size: 14px'
);

if (netlifyIdentity) {
	netlifyIdentity.on('init', user => {
		if (!user) {
			netlifyIdentity.on('login', () => {
				document.location.href = '/admin/';
			});
		}
	});
}

// export const wrapPageElement = ({ element, props }) => {
// 	// props provide same data to Layout as Page element will get
// 	// including location, data, etc - you don't need to pass it
// 	return <AnimatePresence {...props}>{element}</AnimatePresence>;
// };
